import {
	createStore
} from 'vuex'

import * as fb from '../firebase'
import router from '../router'


export default createStore({
	state: {
		userProfile: {},
		granja: {},
		estoque: []
	},
	mutations: {
		setUserProfile(state, val) {
			state.userProfile = val
		},
		setGranja(state, val) {
			state.granja = val
		}
	},
	actions: {
		async signOut({
			commit
		}) {
			await fb.auth.signOut()

			// clear userProfile and redirect to /login
			commit('setUserProfile', {})
			// limpa granja
			commit('setGranja', {})

			router.push('/login')
		},
		userSignedIn({
			dispatch
		}, user) {
			// fetch user profile and set in state
			dispatch('fetchUserProfile', user)
		},

		async fetchGranjaByAlias({
			commit
		}, alias) {
			fb.db.collection('granjas')
				.where("alias", "==", alias)
				.get()
				.then((querySnapshot) => {
					let granja = querySnapshot.docs[0].data()
					commit('setGranja', granja)
				})
				.catch((err) => {
					alert(err.message)
					router.push('/notfound');
				});
		},

		async fetchGranja({ commit }, granjaId) {
			try {
				let granja = await fb.db.collection('granjas').doc(granjaId).get()
				granja = granja.data()

				commit('setGranja', granja)
			} catch (err) {
				// continue
			}
		},

		async fetchUserProfile({
			commit, dispatch
		}, user) {

			if (Object.keys(this.state.userProfile).length === 0) {

				let userProfile = {
					nome: user.displayName,
					email: user.email,
					urlFoto: user.photoURL
				}

				try {
					// fetch user custom profile info
					var userCustomProfile = await fb.db.collection('usuarios').doc(user.uid).get()
					userCustomProfile = userCustomProfile.data()

					Object.assign(userProfile, userCustomProfile)

				} catch (err) {
					// continue
				}
				if (userCustomProfile['granja']) {
					dispatch('fetchGranja', userCustomProfile['granja'])
				}

				// set user profile in state
				commit('setUserProfile', userProfile)

			}
			// change route to dashboard
			if (router.currentRoute.value.name === 'login') {
				router.push('/')
			}

		}
	},
	modules: {}
})