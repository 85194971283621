<template>
	<div>
		<Dialog
			header="Desconectar"
			v-model:visible="display"
			:style="{ width: '350px' }"
			:modal="true"
		>
			<div class="confirmation-content">
				<span>Tem certeza que deseja sair?</span>
			</div>
			<template #footer>
				<Button
					label="Cancelar"
					icon="pi pi-times"
					@click="display = false"
					class="p-button-text"
				/>
				<Button
					label="Sair"
					icon="pi pi-check"
					@click="signOut"
					class="p-button-text"
					autofocus
				/>
			</template>
		</Dialog>
		<div :class="['profile', { 'profile-expanded': expanded }]">
			<div>
				<h3 class="granja-title p-d-md-none">{{granja.nome}}</h3>
			</div>
			<button class="p-link" @click="onClick">
				<span class="profile-name">{{userProfile.nome}}</span>
				<i class="pi pi-fw pi-chevron-down"></i>
				<span class="profile-role">{{userProfile.papel}}</span>
			</button>
		</div>

		<transition name="layout-profile-menu">
			<ul class="layout-menu profile-menu" v-show="expanded">
				<li role="menuitem">
					<a href="#" :tabIndex="expanded ? null : '-1'">
						<i class="pi pi-user"></i>
						<span>Profile</span>
					</a>
					<div class="layout-menu-tooltip">
						<div class="layout-menu-tooltip-arrow"></div>
						<div class="layout-menu-tooltip-text">Profile</div>
					</div>
				</li>
				<li role="menuitem">
					<a href="#" :tabIndex="expanded ? null : '-1'">
						<i class="pi pi-lock"></i>
						<span>Privacy</span>
					</a>
					<div class="layout-menu-tooltip">
						<div class="layout-menu-tooltip-arrow"></div>
						<div class="layout-menu-tooltip-text">Privacy</div>
					</div>
				</li>
				<li role="menuitem">
					<a href="#" :tabIndex="expanded ? null : '-1'">
						<i class="pi pi-cog"></i>
						<span>Settings</span>
					</a>
					<div class="layout-menu-tooltip">
						<div class="layout-menu-tooltip-arrow"></div>
						<div class="layout-menu-tooltip-text">Settings</div>
					</div>
				</li>
				<li role="menuitem">
					<a
						@click="display = true"
						:tabIndex="expanded ? null : '-1'"
					>
						<i class="pi pi-sign-out"></i>
						<span>Logout</span>
					</a>
					<div class="layout-menu-tooltip">
						<div class="layout-menu-tooltip-arrow"></div>
						<div class="layout-menu-tooltip-text">Logout</div>
					</div>
				</li>
			</ul>
		</transition>
	</div>
</template>

<script>
import { mapState } from "vuex";

export default {
	data() {
		return {
			display: false,
		};
	},
	emits: ["profile-click"],
	props: {
		expanded: {
			type: Boolean,
			default: null,
		},
	},
	methods: {
		onClick(event) {
			this.$emit("profile-click", event);
			event.preventDefault();
		},
		signOut() {
			this.display = false;
			this.$store.dispatch("signOut");
		},
	},
	computed: mapState(["userProfile", "granja"]),
	name: "AppInlineProfile",
};
</script>

<style scoped>
.granja-title {
	text-align: center;
	/* color: #28A745; */
	font-weight: 800;
}

.profile-name {
	color: #686868
}
</style>
