import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'
import { auth } from '../firebase'
import store from '../store'

const routes = [
	{
		path: '/',
		name: 'home',
		component: Home,
		meta: {
			requiresAuth: true
		}
	},
	{
		path: '/invoice',
		name: 'invoice',
		component: () => import('../pages/Invoice.vue'),
		meta: {
			requiresAuth: true
		}
	},
	{
		path: '/login',
		name: 'login',
		component: () => import('../pages/Login.vue')
	},
	{
		path: '/:granja/pedido',
		name: 'cliente-pedido',
		component: () => import('../components/FazerPedido.vue')
	},
	{
		path: '/notfound',
		name: 'notfound',
		component: () => import('../pages/NotFound.vue')
	},
	{
		path: "/:pathMatch(.*)*",
		name: "404",
		redirect: '/notfound'
	}
];

const router = createRouter({
	history: createWebHashHistory(process.env.BASE_URL),
	routes
});

router.beforeEach(async (to, from, next) => {
	const requiresAuth = to.matched.some(x => x.meta.requiresAuth)
	window.scrollTo(0, 0);

	
	if (to.matched[0].path == '/:granja/pedido' && !auth.currentUser) {
		
		// Busca alias da granja na URL
		let granjaAlias = to.params.granja
		store.dispatch('fetchGranjaByAlias', granjaAlias)
	}
	
	// TODO - VERIFICAR SE USUÁRIO ESTÁ LOGADO E FORÇAR SALVAR
	
	// filtra páginas que precisam ser autenticadas
	if (requiresAuth && !auth.currentUser) {
		next('/login')
	} else {
		next()
	}
});

export default router;
