<template>
	<div :class="containerClass" @click="onDocumentClick">
		<div class="layout-wrapper-content">
			<AppTopBar
				:topbarMenuActive="topbarMenuActive"
				:profileMode="profileMode"
				:horizontal="layoutMode === 'horizontal'"
				:activeTopbarItem="activeTopbarItem"
				@menubutton-click="onMenuButtonClick"
				@topbar-menubutton-click="onTopbarMenuButtonClick"
				@topbar-item-click="onTopbarItemClick"
			></AppTopBar>

			<transition name="layout-menu-container">
				<div
					:class="menuClass"
					@click="onMenuClick"
					v-show="isMenuVisible()"
				>
					<div class="menu-scroll-content">
						<AppInlineProfile
							v-if="
								profileMode === 'inline' &&
								layoutMode !== 'horizontal'
							"
							@profile-click="onProfileClick"
							:expanded="profileExpanded"
						></AppInlineProfile>

						<AppMenu
							:model="menu"
							:layoutMode="layoutMode"
							:active="menuActive"
							:mobileMenuActive="staticMenuMobileActive"
							@menuitem-click="onMenuItemClick"
							@root-menuitem-click="onRootMenuItemClick"
						></AppMenu>
					</div>
				</div>
			</transition>

			<div class="layout-main">
				<router-view />
			</div>

			<AppConfig
				:layoutMode="layoutMode"
				@layout-change="onLayoutChange"
				:darkMenu="darkMenu"
				@menu-color-change="onMenuColorChange"
				:profileMode="profileMode"
				@profile-mode-change="onProfileModeChange"
				:layout="layout"
				:layouts="layoutColors"
				:layoutSpecialColors="layoutSpecialColors"
				@layout-color-change="onLayoutColorChange"
				:theme="theme"
				:themes="themeColors"
				@theme-change="onThemeChange"
			></AppConfig>

			<div class="layout-mask"></div>

			<AppFooter />
		</div>
	</div>
</template>

<script>
import AppTopBar from "./AppTopbar.vue";
import AppConfig from "./AppConfig.vue";
import AppInlineProfile from "./AppInlineProfile.vue";
import AppMenu from "./AppMenu.vue";
import AppFooter from "./AppFooter.vue";
import EventBus from "./event-bus";
import { auth } from "./firebase";

export default {
	data() {
		return {
			layoutMode: "static",
			profileMode: "inline",
			overlayMenuActive: false,
			staticMenuDesktopInactive: false,
			staticMenuMobileActive: false,
			topbarMenuActive: false,
			activeTopbarItem: null,
			rotateMenuButton: false,
			darkMenu: false,
			menu: [
				{
					label: "Administração",
					icon: "pi pi-fw pi-home",
					items: [
						{
							label: "Painel",
							// icon: "fas fa-tachometer-alt",
							icon: "pi pi-fw pi-chart-bar",
							to: "/dashboard",
						},
						{
							label: "Equipe",
							icon: "pi pi-fw pi-users",
							to: "/equipe",
						},
					],
				},
				{
					label: "Financeiro",
					icon: "pi pi-fw pi-dollar",
					items: [
						{
							label: "Entradas",
							// icon: "fas fa-dollar-sign",
							icon: "pi pi-fw pi-download",
							material_icon: "inbox",
							items: [
								{
									label: "Vendas",
									icon: "pi pi-fw pi-wallet",
									material_icon: "outbox",
								},
								{
									label: "Pedidos",
									icon: "pi pi-fw pi-file",
									material_icon: "",
									to: "/pedidos_venda",
								},
							],
						},
						{
							label: "Saídas",
							// icon: "fas fa-cart-arrow-down",
							icon: "pi pi-fw pi-upload",
							material_icon: "outbox",
							items: [
								{
									label: "Aquisições",
									icon: "pi pi-fw pi-shopping-cart",
									to: "/aquisicoes",
								},
								{
									label: "Pedidos",
									icon: "pi pi-fw pi-file",
									to: "/pedidos_aquisicao",
								},
							],
						},
						{
							label: "Arquivos",
							icon: "pi pi-fw pi-inbox",
							material_icon: "",
							to: "/arquivos",
						},
					],
				},
				{
					label: "Granja",
					icon: "pi pi-fw pi-star",
					items: [
						{
							label: "Estoque",
							// icon: "fas fa-boxes",
							icon: "pi pi-fw pi-th-large",
							to: "/estoque",
						},
						{
							label: "Barracões",
							// icon: "fas fa-warehouse",
							icon: "pi pi-fw pi-home",
							to: "/barracoes",
						},
					],
				},
			],
			menuActive: false,
			profileExpanded: false,
			theme: "green",
			themeColors: [
				{ name: "Amber", file: "amber", color: "#F8BD0C" },
				{ name: "Blue", file: "blue", color: "#007bff" },
				{ name: "Cyan", file: "cyan", color: "#17A2B8" },
				{ name: "Indigo", file: "indigo", color: "#6610F2" },
				{ name: "Purple", file: "purple", color: "#883cae" },
				{ name: "Teal", file: "teal", color: "#20C997" },
				{ name: "Orange", file: "orange", color: "#FD7E14" },
				{ name: "Deep Purple", file: "deeppurple", color: "#612FBE" },
				{ name: "Light Blue", file: "lightblue", color: "#4DA3FF" },
				{ name: "Green", file: "green", color: "#28A745" },
				{ name: "Light Green", file: "lightgreen", color: "#61CC79" },
				{ name: "Brown", file: "brown", color: "#986839" },
				{ name: "Dark Grey", file: "darkgrey", color: "#6C757D" },
				{ name: "Pink", file: "pink", color: "#E83E8C" },
				{ name: "Lime", file: "lime", color: "#74CD32" },
			],
			layoutColors: [
				{
					name: "Blue",
					file: "blue",
					color1: "#146fd7",
					color2: "#146fd7",
				},
				{
					name: "Cyan",
					file: "cyan",
					color1: "#0A616F",
					color2: "#0D7A8B",
				},
				{
					name: "Indigo",
					file: "indigo",
					color1: "#470EA2",
					color2: "#510DBE",
				},
				{
					name: "Purple",
					file: "purple",
					color1: "#391F68",
					color2: "#4E2A8F",
				},
				{
					name: "Teal",
					file: "teal",
					color1: "#136E52",
					color2: "#13956E",
				},
				{
					name: "Pink",
					file: "pink",
					color1: "#771340",
					color2: "#B12D69",
				},
				{
					name: "Lime",
					file: "lime",
					color1: "#407916",
					color2: "#569D21",
				},
				{
					name: "Green",
					file: "green",
					color1: "#1F8E38",
					color2: "#1F8E38",
				},
				{
					name: "Amber",
					file: "amber",
					color1: "#7A5E06",
					color2: "#C5980F",
				},
				{
					name: "Brown",
					file: "brown",
					color1: "#593E22",
					color2: "#6F4925",
				},
				{
					name: "Orange",
					file: "orange",
					color1: "#904100",
					color2: "#CB5C00",
				},
				{
					name: "Deep Purple",
					file: "deeppurple",
					color1: "#341A64",
					color2: "#46208E",
				},
				{
					name: "Light Blue",
					file: "lightblue",
					color1: "#14569D",
					color2: "#2A7BD1",
				},
				{
					name: "Light Green",
					file: "lightgreen",
					color1: "#2E8942",
					color2: "#3EA655",
				},
				{
					name: "Dark Grey",
					file: "darkgrey",
					color1: "#343A40",
					color2: "#424A51",
				},
			],
			layoutSpecialColors: [
				{
					name: "Influenza",
					file: "influenza",
					color1: "#a83279",
					color2: "#f38e00",
				},
				{
					name: "Calm",
					file: "calm",
					color1: "#5f2c82",
					color2: "#0DA9A4",
				},
				{
					name: "Crimson",
					file: "crimson",
					color1: "#521c52",
					color2: "#c6426e",
				},
				{
					name: "Night",
					file: "night",
					color1: "#2c0747",
					color2: "#6441a5",
				},
				{
					name: "Skyline",
					file: "skyline",
					color1: "#2b32b2",
					color2: "#1488cc",
				},
				{
					name: "Sunkist",
					file: "sunkist",
					color1: "#ee8a21",
					color2: "#f2c94c",
				},
				{
					name: "Little Leaf",
					file: "littleleaf",
					color1: "#4DB865",
					color2: "#80D293",
				},
				{
					name: "Joomla",
					file: "joomla",
					color1: "#1e3c72",
					color2: "#2a5298",
				},
				{
					name: "Firewatch",
					file: "firewatch",
					color1: "#cb2d3e",
					color2: "#ef473a",
				},
				{
					name: "Suzy",
					file: "suzy",
					color1: "#834d9b",
					color2: "#d04ed6",
				},
			],
			layout: "blue",
		};
	},
	watch: {
		$route() {
			this.menuActive = false;
			this.$toast.removeAllGroups();
		},
	},
	created() {
		if (!auth.currentUser) this.layoutMode = "horizontal";
		else this.layoutMode = "static";
	},
	methods: {
		onDocumentClick() {
			if (!this.topbarItemClick) {
				this.activeTopbarItem = null;
				this.topbarMenuActive = false;
			}

			if (!this.menuClick) {
				if (this.isHorizontal() || this.isSlim()) {
					this.menuActive = false;
					EventBus.emit("reset-active-index");
				}

				this.hideOverlayMenu();
			}

			this.topbarItemClick = false;
			this.menuClick = false;
		},
		onProfileClick(event) {
			this.profileExpanded = !this.profileExpanded;
			if (this.isHorizontal() || this.isSlim()) {
				EventBus.emit("reset-active-index");
			}

			event.preventDefault();
		},
		onMenuClick() {
			this.menuClick = true;
		},
		onMenuItemClick(event) {
			if (!event.item.items) {
				EventBus.emit("reset-active-index");
				this.hideOverlayMenu();
			}
			if (!event.item.items && this.isHorizontal()) {
				this.menuActive = false;
			}
		},
		onRootMenuItemClick() {
			this.menuActive = !this.menuActive;
		},
		onMenuButtonClick(event) {
			this.menuClick = true;

			this.rotateMenuButton = !this.rotateMenuButton;
			this.topbarMenuActive = false;

			if (this.layoutMode === "overlay") {
				this.overlayMenuActive = !this.overlayMenuActive;
			} else {
				if (this.isDesktop())
					this.staticMenuDesktopInactive = !this
						.staticMenuDesktopInactive;
				else this.staticMenuMobileActive = !this.staticMenuMobileActive;
			}

			event.preventDefault();
		},
		onTopbarMenuButtonClick(event) {
			this.topbarItemClick = true;
			this.topbarMenuActive = !this.topbarMenuActive;
			this.hideOverlayMenu();
			event.preventDefault();
		},
		onTopbarItemClick(event) {
			this.topbarItemClick = true;

			if (this.activeTopbarItem === event.item)
				this.activeTopbarItem = null;
			else this.activeTopbarItem = event.item;

			event.originalEvent.preventDefault();
		},
		hideOverlayMenu() {
			this.rotateMenuButton = false;
			this.overlayMenuActive = false;
			this.staticMenuMobileActive = false;
		},
		isDesktop() {
			return window.innerWidth > 1024;
		},
		isHorizontal() {
			return this.layoutMode === "horizontal";
		},
		isSlim() {
			return this.layoutMode === "slim";
		},
		isMenuVisible() {
			if (!auth.currentUser) return false;
			if (this.isDesktop()) {
				if (this.layoutMode === "static")
					return !this.staticMenuDesktopInactive;
				else if (this.layoutMode === "overlay")
					return this.overlayMenuActive;
				else return true;
			} else {
				if (this.staticMenuMobileActive) return true;
				else if (this.layoutMode === "overlay")
					return this.overlayMenuActive;
				else return true;
			}
		},
		onLayoutChange(layoutMode) {
			this.layoutMode = layoutMode;
			this.staticMenuDesktopInactive = false;
			this.overlayMenuActive = false;

			if (this.isHorizontal()) {
				this.profileMode = "top";
				this.profileMenuActive = false;
			}
		},
		onMenuColorChange(menuColor) {
			this.darkMenu = menuColor;
		},
		onProfileModeChange(profileMode) {
			this.profileMode = profileMode;
		},
		onLayoutColorChange(layout, special) {
			this.layout = layout;
			this.changeStyleSheetUrl("layout-css", layout, "layout");

			if (special) {
				this.darkMenu = true;
			}
		},
		onThemeChange(theme) {
			this.theme = theme;
			this.changeStyleSheetUrl("theme-css", theme, "theme");
		},
		changeStyleSheetUrl(id, value, prefix) {
			let element = document.getElementById(id);
			let urlTokens = element.getAttribute("href").split("/");
			urlTokens[urlTokens.length - 1] = prefix + "-" + value + ".css";
			let newURL = urlTokens.join("/");

			this.replaceLink(element, newURL);
		},
		replaceLink(linkElement, href) {
			const id = linkElement.getAttribute("id");
			const cloneLinkElement = linkElement.cloneNode(true);

			cloneLinkElement.setAttribute("href", href);
			cloneLinkElement.setAttribute("id", id + "-clone");

			linkElement.parentNode.insertBefore(
				cloneLinkElement,
				linkElement.nextSibling
			);

			cloneLinkElement.addEventListener("load", () => {
				linkElement.remove();
				cloneLinkElement.setAttribute("id", id);
			});
		},
	},
	computed: {
		containerClass() {
			return [
				"layout-wrapper",
				{
					"menu-layout-static": this.layoutMode !== "overlay",
					"menu-layout-overlay": this.layoutMode === "overlay",
					"layout-menu-overlay-active": this.overlayMenuActive,
					"menu-layout-slim": this.layoutMode === "slim",
					"menu-layout-horizontal": this.layoutMode === "horizontal",
					"layout-menu-static-inactive": this
						.staticMenuDesktopInactive,
					"layout-menu-static-active": this.staticMenuMobileActive,
					"p-input-filled": this.$appState.inputStyle === "filled",
					"p-ripple-disabled": this.$primevue.config.ripple === false,
				},
			];
		},
		menuClass() {
			return [
				"layout-menu-container",
				{ "layout-menu-dark": this.darkMenu },
			];
		},
	},
	components: {
		AppTopBar: AppTopBar,
		AppConfig: AppConfig,
		AppInlineProfile: AppInlineProfile,
		AppMenu: AppMenu,
		AppFooter: AppFooter,
	},
};
</script>

<style lang="scss">
@import "./App.scss";

@font-face {
	font-family: "Bebas Neue";
	src: local("Bebas Neue"),
		url(./fonts/BebasNeue/BebasNeue-Regular.ttf) format("truetype");
}
</style>
