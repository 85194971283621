<template>
	<div class="topbar clearfix">
		<div class="topbar-left">
			<button class="p-link" @click="goDashboard">
				<img
					alt="Logo"
					src="assets/layout/images/logo.png"
					class="topbar-logo"
				/>
			</button>
		</div>

		<div class="topbar-right">
			<button class="p-link" id="menu-button" @click="onMenuButtonClick">
				<i class="pi pi-angle-left"></i>
			</button>

			<button
				class="p-link p-text-nowrap p-text-truncate title-button"
				@click="goDashboard"
			>
				<h1 class="granja-title p-d-none p-d-md-inline-flex">
					{{ granja.nome }}
				</h1>
			</button>

			<ul :class="topbarItemsClass" v-if="Object.keys(userProfile).length > 0">
				<li>
					<button class="p-link">
						<i
							class="topbar-icon pi pi-fw pi-bell"
							v-badge.warning="4"
						></i>
						<span class="topbar-item-name">Notifications</span>
					</button>

					<transition name="layout-submenu-container">
						<ul
							class="layout-menu fadeInDown"
							v-show="activeTopbarItem === 'notifications'"
						>
							<li role="menuitem">
								<a href="#">
									<i class="pi pi-fw pi-sliders-h"></i>
									<span>Pending tasks</span>
								</a>
							</li>
							<li role="menuitem">
								<a href="#">
									<i class="pi pi-fw pi-calendar"></i>
									<span>Meeting today at 3pm</span>
								</a>
							</li>
							<li role="menuitem">
								<a href="#">
									<i class="pi pi-fw pi-download"></i>
									<span>Download documents</span>
								</a>
							</li>
							<li role="menuitem">
								<a href="#">
									<i class="pi pi-fw pi-bookmark"></i>
									<span>Book flight</span>
								</a>
							</li>
						</ul>
					</transition>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>

import {mapState} from 'vuex'

export default {
	props: {
		topbarMenuActive: Boolean,
		profileMode: String,
		horizontal: Boolean,
		activeTopbarItem: String,
	},
	methods: {
		onMenuButtonClick(event) {
			this.$emit("menubutton-click", event);
		},
		onTopbarMenuButtonClick(event) {
			this.$emit("topbar-menubutton-click", event);
		},
		goDashboard() {
			window.location = "/#/";
		},	
	},
	computed: {...mapState(['userProfile','granja']),
		topbarItemsClass() {
			return [
				"topbar-items fadeInDown",
				{
					"topbar-items-visible": this.topbarMenuActive,
				},
			];
		},
	},
};
</script>
<style scoped>
h1 {
	color: #ffffff;
	font-family: "Bebas Neue", sans-serif;
	font-size: 42px;
	margin: 0;
	text-align: center;
}

.title-button {
	position: relative;
	top: -7px;
	margin-left: 40px;
}

h2 {
	color: #ffffff;
	font-family: "Raleway", sans-serif;
	font-size: 30px;
	font-weight: 800;
	line-height: 36px;
	margin: 0 0 24px;
	text-align: center;
}

p {
	color: #f8f8f8;
	font-family: "Raleway", sans-serif;
	font-size: 18px;
	font-weight: 500;
	line-height: 32px;
	margin: 0 0 24px;
}
</style>
