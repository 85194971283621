<template>
	<div class="login-body">
		<Toast />
		<Dialog
			header="Recuperação de senha"
			v-model:visible="display"
			:style="{ width: '350px' }"
			:modal="true"
		>
			<div class="confirmation-content">
				<span
					>Deseja enviar e-mail de recuperação de senha para
					<h3>{{ passwordResetEmail }}</h3>
					?</span
				>
			</div>
			<template #footer>
				<Button
					label="Não"
					icon="pi pi-times"
					@click="display = false"
					class="p-button-text"
				/>
				<Button
					label="Sim"
					icon="pi pi-check"
					@click="sendPasswordReset"
					class="p-button-text"
					autofocus
				/>
			</template>
		</Dialog>
		<div class="login-image"></div>
		<div v-if="showLoginForm" class="card login-panel p-fluid">
			<form @submit.prevent>
				<div class="login-panel-content">
					<div class="p-grid">
						<div class="p-col-3" style="text-align: left">
							<img
								src="assets/layout/images/login/icon-login.svg"
								alt="avalon-vue"
							/>
						</div>
						<div class="p-col-9" style="text-align: left">
							<h2 class="welcome-text">Bem-vindo!</h2>
							<span class="guest-sign-in"
								>Entre com seu e-mail</span
							>
						</div>
						<div class="p-col-12" style="text-align: left">
							<label class="login-label">e-mail</label>
							<div class="login-input">
								<InputText
									ref="email-input"
									:class="{ 'p-invalid': isEmailInvalid }"
									:required="true"
									v-on:keyup="checkFormInput"
									v-model.trim="loginForm.email"
									placeholder="e-mail"
								/>
							</div>
						</div>
						<div class="p-col-12" style="text-align: left">
							<label class="login-label">Senha</label>
							<div class="login-input">
								<InputText
									ref="password-input"
									:class="{ 'p-invalid': isPasswordInvalid }"
									v-model.trim="loginForm.password"
									:required="true"
									v-on:keyup="checkFormInput"
									type="password"
									placeholder="senha"
								/>
							</div>
						</div>
						<div class="p-col-12 p-md-6 button-pane">
							<Button
								ref="submit-button"
								type="submit"
								label="Entrar"
								@click="signIn()"
							/>
						</div>
						<div class="p-col-12 p-md-6 link-pane">
							<button class="p-link" @click="toggleForm()">
								Esqueceu sua senha?
							</button>
						</div>
					</div>
				</div>
			</form>
		</div>
		<div v-else class="card login-panel p-fluid">
			<form @submit.prevent>
				<div class="login-panel-content">
					<div class="p-grid">
						<div class="p-col-3" style="text-align: left">
							<img
								src="assets/layout/images/login/icon-login.svg"
								alt="avalon-vue"
							/>
						</div>
						<div class="p-col-9" style="text-align: left">
							<h2 class="welcome-text">Esqueceu sua senha?</h2>
							<span class="guest-sign-in"
								>Vamos resolver isso. Insira seu e-mail</span
							>
						</div>
						<div class="p-col-12" style="text-align: left">
							<label class="login-label">e-mail</label>
							<div class="login-input">
								<InputText
									:class="{
										'p-invalid': isResetPasswordInvalid,
									}"
									:required="true"
									v-on:keyup="checkFormInput"
									v-model.trim="passwordResetEmail"
									placeholder="e-mail"
								/>
							</div>
						</div>
						<div class="p-col-12 p-md-6 button-pane">
							<Button
								label="Enviar e-mail"
								type="submit"
								@click="promptConfirmation"
							/>
						</div>
						<div class="p-col-12 p-md-6 link-pane">
							<button class="p-link" @click="toggleForm()">
								Voltar para o login
							</button>
						</div>
					</div>
				</div>
			</form>
		</div>
	</div>
</template>

<script>
import { auth } from "../firebase";

export default {
	name: "Login",
	data() {
		return {
			loginForm: {
				email: "",
				password: "",
			},
			errorMessage: "",
			display: false,
			passwordResetEmail: "",
			showLoginForm: true,
			isEmailInvalid: false,
			isPasswordInvalid: false,
			isResetPasswordInvalid: false,
		};
	},
	methods: {
		async signIn() {
			this.checkFormInput();

			if (this.isEmailInvalid || this.isPasswordInvalid) return;
			
			auth
				.signInWithEmailAndPassword(
					this.loginForm.email,
					this.loginForm.password
				)
				.then((user) => {
					this.$store.dispatch("fetchUserProfile", user.user);
					// resolve();
				})
				.catch((err) => {
					this.loginForm.email = "";
					this.loginForm.password = "";

					this.errorMessage = err.message;
					this.$toast.add({
						severity: "error",
						summary: "Erro",
						detail: err.message,
						life: 10000,
					});
					this.$$refs.emailInput.focus();
					// reject();
				});
		},
		toggleForm() {
			this.isEmailInvalid = false;
			this.isPasswordInvalid = false;
			this.isResetPasswordInvalid = false;

			this.loginForm.email = "";
			this.loginForm.password = "";
			this.passwordResetEmail = "";

			this.showLoginForm = !this.showLoginForm;
		},
		promptConfirmation() {
			this.checkFormInput();
			if (!this.passwordResetEmail) return;

			this.display = true;
		},
		async sendPasswordReset() {
			this.display = false;

			try {
				await auth.sendPasswordResetEmail(this.passwordResetEmail);

				const passwordResetSuccess = `E-mail enviado para ${this.passwordResetEmail}. 
        Acesse seu e-mail para redefinir a senha.`;

				this.$toast.add({
					severity: "success",
					summary: "Sucesso!",
					detail: passwordResetSuccess,
					life: 10000,
				});
				this.toggleForm();
			} catch (err) {
				this.isResetPasswordInvalid = true;

				this.$toast.add({
					severity: "error",
					summary: "Erro",
					detail: err.message,
					life: 10000,
				});
				return;
			}
		},
		checkFormInput() {
			this.isEmailInvalid = this.loginForm.email.length == 0;
			this.isPasswordInvalid = this.loginForm.password.length == 0;
			this.isResetPasswordInvalid = this.passwordResetEmail.length == 0;
		},
	},
};
</script>

<style scoped>
</style>