import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'

// firebase init - add your own config here
const firebaseConfig = {
	apiKey: "AIzaSyDC02_YXHsdrWbsJ1oBXznSPp18f9yUMIQ",
	authDomain: "granjas-kalbas.firebaseapp.com",
	projectId: "granjas-kalbas",
	storageBucket: "granjas-kalbas.appspot.com",
	messagingSenderId: "547861606637",
	appId: "1:547861606637:web:ad7c117c48c259dcd49ce4",
	measurementId: "G-KG7JLH447L"
};
if (firebase.apps.length === 0) {
	firebase.initializeApp(firebaseConfig)
}

// utils
const db = firebase.firestore()
const auth = firebase.auth()

if (location.hostname === 'localhost') {
	auth.useEmulator("http://localhost:9099")
	db.useEmulator('localhost', 8080)
}


// export utils/refs
export {
	db,
	auth
}