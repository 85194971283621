<template>
	<div class="exception-body access">
		<div class="exception-text">
			<div class="exception-box">
				<span>Access</span>
			</div>
			<span> Denied</span>
		</div>
		<div class="exception-panel">
			<div class="exception-image">
				<img
					src="assets/layout/images/exception/icon-error.png"
					alt="avalon-vue"
				/>
			</div>
			<div class="exception-panel-content">
				<div class="information-text">
					<h3>Access denied to this resource.</h3>
					<p>You don't have the necessary permission.</p>
				</div>
				<Button label="Go To Dashboard" @click="goDashboard" />
			</div>
		</div>
	</div>
</template>

<script>
export default {
	methods: {
		goDashboard() {
			window.location = "/";
		},
	},
};
</script>

<style scoped>
</style>