<template>
	<div class="exception-body notfound">
		<div class="exception-text">
			<div class="notfound-box">
				<span>404</span>
			</div>
			<span class="notfound-text"> Não Encontrado</span>
		</div>
		<div class="exception-panel">
			<div class="exception-image">
				<img
					src="assets/layout/images/exception/icon-error.png"
					alt="avalon-vue"
				/>
			</div>
			<div class="exception-panel-content">
				<div class="information-text">
					<h3>Isso não deveria ter acontecido...</h3>
					<p>Melhor voltar à pagina inicial e esquecer isso.</p>
				</div>
				<Button label="Voltar para casa" @click="goDashboard" />
			</div>
		</div>
	</div>
</template>

<script>
export default {
	methods: {
		goDashboard() {
			window.location = "/";
		},
	},
};
</script>

<style scoped>
</style>