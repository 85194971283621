<template>
	<div id="layout-config" :class="containerClass">
		<a
			href="#"
			class="layout-config-button"
			id="layout-config-button"
			@click="toggleConfigurator"
		>
			<i class="pi pi-cog"></i>
		</a>
		<a href="#" class="layout-config-close" @click="hideConfigurator">
			<i class="pi pi-times"></i>
		</a>

		<div class="layout-config-content">
			<h5>Menu Mode</h5>
			<div class="p-grid">
				<div class="p-col-6">
					<div class="p-field-radiobutton">
						<RadioButton
							id="static"
							name="layoutMode"
							value="static"
							v-model="d_layoutMode"
							@change="changeLayout($event, 'static')"
						/>
						<label for="static">Static</label>
					</div>
				</div>
				<div class="p-col-6">
					<div class="p-field-radiobutton">
						<RadioButton
							id="overlay"
							name="layoutMode"
							value="overlay"
							v-model="d_layoutMode"
							@change="changeLayout($event, 'overlay')"
						/>
						<label for="overlay">Overlay</label>
					</div>
				</div>
			</div>
			<div class="p-grid">
				<div class="p-col-6">
					<div class="p-field-radiobutton">
						<RadioButton
							id="horizontal"
							name="layoutMode"
							value="horizontal"
							v-model="d_layoutMode"
							@change="changeLayout($event, 'horizontal')"
						/>
						<label for="horizontal">Horizontal</label>
					</div>
				</div>
				<div class="p-col-6">
					<div class="p-field-radiobutton">
						<RadioButton
							id="slim"
							name="layoutMode"
							value="slim"
							v-model="d_layoutMode"
							@change="changeLayout($event, 'slim')"
						/>
						<label for="slim">Slim</label>
					</div>
				</div>
			</div>

			<h5 style="margin-top: 0">Menu Theme</h5>
			<div class="p-grid">
				<div class="p-col-6">
					<div class="p-field-radiobutton">
						<RadioButton
							id="dark"
							name="menuColor"
							:value="true"
							v-model="d_darkMenu"
							@change="changeMenuColor($event, true)"
						/>
						<label for="dark">Dark</label>
					</div>
				</div>
				<div class="p-col-6">
					<div class="p-field-radiobutton">
						<RadioButton
							id="light"
							name="menuColor"
							:value="false"
							v-model="d_darkMenu"
							@change="changeMenuColor($event, false)"
						/>
						<label for="light">Light</label>
					</div>
				</div>
			</div>

			<h5 style="margin-top: 0">User Profile Mode</h5>
			<div class="p-grid">
				<div class="p-col-6">
					<div class="p-field-radiobutton">
						<RadioButton
							id="inline"
							name="profileMode"
							value="inline"
							:disabled="layoutMode === 'horizontal'"
							v-model="d_profileMode"
							@change="changeProfileMode($event, 'inline')"
						/>
						<label for="inline">Inline</label>
					</div>
				</div>
				<div class="p-col-6">
					<div class="p-field-radiobutton">
						<RadioButton
							id="top"
							name="profileMode"
							value="top"
							:disabled="layoutMode === 'horizontal'"
							v-model="d_profileMode"
							@change="changeProfileMode($event, 'top')"
						/>
						<label for="top">Overlay</label>
					</div>
				</div>
			</div>

			<h5 style="margin-top: 0">Input Style</h5>
			<div class="p-formgroup-inline">
				<div class="p-field-radiobutton">
					<RadioButton
						id="input_outlined"
						name="inputstyle"
						value="outlined"
						:modelValue="value"
						@update:modelValue="onChange"
					/>
					<label for="input_outlined">Outlined</label>
				</div>
				<div class="p-field-radiobutton">
					<RadioButton
						id="input_filled"
						name="inputstyle"
						value="filled"
						:modelValue="value"
						@update:modelValue="onChange"
					/>
					<label for="input_filled">Filled</label>
				</div>
			</div>

			<h5 style="margin-top: 0">Ripple Effect</h5>
			<InputSwitch
				:modelValue="rippleActive"
				@update:modelValue="onRippleChange"
			/>

			<h5>Flat Layout Colors</h5>
			<div class="layout-themes">
				<div v-for="l of layouts" :key="l.name">
					<a
						href="#"
						@click="changeLayoutColor($event, l.file, false)"
						:style="{
							backgroundImage: `linear-gradient(to right, ${l.color1}, ${l.color2})`,
						}"
					>
						<i class="pi pi-check" v-if="layout === l.file"></i>
					</a>
				</div>
			</div>

			<h5>Special Layout Colors</h5>
			<div class="layout-themes">
				<div v-for="l of layoutSpecialColors" :key="l.name">
					<a
						href="#"
						@click="changeLayoutColor($event, l.file, true)"
						:style="{
							backgroundImage: `linear-gradient(to right, ${l.color1}, ${l.color2})`,
						}"
					>
						<i class="pi pi-check" v-if="layout === l.file"></i>
					</a>
				</div>
			</div>

			<h5>Component Themes</h5>
			<div class="layout-themes">
				<div v-for="t of themes" :key="t.name">
					<a
						href="#"
						@click="changeTheme($event, t.file)"
						:style="{ backgroundColor: t.color }"
					>
						<i class="pi pi-check" v-if="theme === t.file"></i>
					</a>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	emits: [
		"layout-change",
		"menu-color-change",
		"profile-mode-change",
		"layout-color-change",
		"theme-change",
	],
	props: {
		layoutMode: {
			type: String,
			default: null,
		},
		darkMenu: {
			type: Boolean,
			default: null,
		},
		profileMode: {
			type: String,
			default: null,
		},
		layout: {
			type: String,
			default: null,
		},
		layouts: {
			type: Array,
			default: null,
		},
		layoutSpecialColors: {
			type: Array,
			default: null,
		},
		theme: {
			type: String,
			default: null,
		},
		themes: {
			type: Array,
			default: null,
		},
	},
	data() {
		return {
			active: false,
			d_layoutMode: this.layoutMode,
			d_darkMenu: this.darkMenu,
			d_profileMode: this.profileMode,
		};
	},
	watch: {
		$route() {
			if (this.active) {
				this.active = false;
				this.unbindOutsideClickListener();
			}
		},
		layoutMode(newValue) {
			this.d_layoutMode = newValue;
		},
		darkMenu(newValue) {
			this.d_darkMenu = newValue;
		},
		profileMode(newValue) {
			this.d_profileMode = newValue;
		},
	},
	outsideClickListener: null,
	methods: {
		toggleConfigurator(event) {
			this.active = !this.active;
			event.preventDefault();

			if (this.active) this.bindOutsideClickListener();
			else this.unbindOutsideClickListener();
		},
		hideConfigurator(event) {
			this.active = false;
			this.unbindOutsideClickListener();
			event.preventDefault();
		},
		onChange(value) {
			this.$appState.inputStyle = value;
		},
		onRippleChange(value) {
			this.$primevue.config.ripple = value;
		},
		changeLayout(event, layoutMode) {
			this.$emit("layout-change", layoutMode);
			event.preventDefault();
		},
		changeMenuColor(event, menuColor) {
			this.$emit("menu-color-change", menuColor);
			event.preventDefault();
		},
		changeProfileMode(event, profileMode) {
			this.$emit("profile-mode-change", profileMode);
			event.preventDefault();
		},
		changeLayoutColor(event, layout, special) {
			this.$emit("layout-color-change", layout, special);
			event.preventDefault();
		},
		changeTheme(event, theme) {
			this.$emit("theme-change", theme);
			event.preventDefault();
		},
		bindOutsideClickListener() {
			if (!this.outsideClickListener) {
				this.outsideClickListener = (event) => {
					if (this.active && this.isOutsideClicked(event)) {
						this.active = false;
					}
				};
				document.addEventListener("click", this.outsideClickListener);
			}
		},
		unbindOutsideClickListener() {
			if (this.outsideClickListener) {
				document.removeEventListener(
					"click",
					this.outsideClickListener
				);
				this.outsideClickListener = null;
			}
		},
		isOutsideClicked(event) {
			return !(
				this.$el.isSameNode(event.target) ||
				this.$el.contains(event.target)
			);
		},
	},
	computed: {
		containerClass() {
			return ["layout-config", { "layout-config-active": this.active }];
		},
		rippleActive() {
			return this.$primevue.config.ripple;
		},
		value() {
			return this.$appState.inputStyle;
		},
	},
};
</script>
